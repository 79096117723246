@use 'sass:math';

@use '_modules' as *;

$button-size: 40px;

document-viewer {
  position: relative;

  .text {
    margin-bottom: $spacer0;
  }

  &[data-layout='list'] {
    tr td {
      padding: 0 !important;
    }
    .pdf-link {
      padding: $gap0 $gap;
      display: flex;
      text-decoration: none;
      align-items: center;
      transition: color $transition;
      img {
        border: 1px solid $grey4;
        margin-right: $gap;
      }
      span {
        margin-right: $gap;
      }
      svg {
        margin-left: auto;
        --fill: #{$red};
        width: 20px;
        height: 20px;
      }
    }
  }

  .card .pdf-link {
    position: relative;
    padding: $gap0;
    text-decoration: none;
    align-items: center;
    border: 1px solid $grey4;
    transition: border-color $transition, transform 0.3s $ease-out-back;
    background-color: white;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }

    svg {
      --fill: #{$red};
      --icon-size: clamp(20px, 100%, 50px);
      transition: transform 0.3s $ease-out-back, opacity $transition;
      position: absolute;

      top: calc(50% - (var(--icon-size) / 2));
      left: calc(50% - (var(--icon-size) / 2));
      width: var(--icon-size);
      height: var(--icon-size);
      opacity: 0;

      @supports not (width: clamp(1px, 5vw, 10px)) {
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
      }
    }

    @include hover {
      border-color: $red;
      transform: scale(1.05);
      svg {
        transform: scale(1.2);
        opacity: 1 !important;
      }
    }
  }

  &[data-layout='grid'] {
    .container {
      display: grid;
      grid-gap: $gutter2 $gutter;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      // align-items: baseline;
      @include break-in(medium) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include break-in(small) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      span {
        margin-top: $gap0;
        font-weight: bold;
        color: $blue;
        font-size: font-size(14);
      }
    }
  }

  &[data-layout='scroller'] {
    position: relative;

    @include break-out('small-medium') {
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr minmax(var(--featured-card-width), auto);
      grid-template-rows: repeat(2, auto);
      grid-gap: 0 $gutter3;
      justify-items: flex-end;

      --card-width: 220px;
      --featured-card-width: 350px;

      &.loading {
        .featured.card {
          &::after {
            @include loader-animation;
          }
          img,
          span {
            opacity: 0;
          }
        }
        .container,
        .featured.card {
          pointer-events: none;
        }
      }

      .introduction.text {
        grid-column: 1;
        grid-row: 1;
        height: fit-content;
        max-width: 900px;
        justify-self: flex-start;
        margin-bottom: 0;
      }

      .container {
        display: flex;
        position: relative;
        height: fit-content;
        width: calc(100% + var(--computed-gutter) + #{$gutter3});
        left: $gutter3;
        box-sizing: border-box;
        grid-column: 1;
        grid-row: 2;
        overflow: hidden;
        padding-left: calc(var(--computed-gutter));
        padding-right: $gutter3;
        padding-top: $spacer;
        padding-bottom: $spacer;
        scroll-behavior: smooth;
        .bounds {
          display: grid;
          grid-gap: $gutter2;
          .card {
            width: var(--card-width);
            grid-row: 1;
            scroll-snap-align: start;
            .pdf-link {
              height: 100%;
              svg {
                display: none;
              }
            }

            span {
              position: relative;
              bottom: -1.5rem;
            }
          }
        }
        @include browser-without-feature('touch') {
          ~ button.navigation {
            opacity: 0;
          }
          @include hover {
            ~ button.navigation {
              opacity: 1;
            }
          }
        }
        @include browser-feature('touch') {
          overflow-x: auto;
        }
      }

      &.scrolled-right button.next,
      &.scrolled-left button.previous {
        pointer-events: none;
        opacity: 0 !important;
      }

      button.navigation {
        --button-size: #{$button-size};
        grid-column: 1;
        grid-row: 2;
        background-color: white;
        border: 1px solid $grey4;
        border-radius: 50%;
        z-index: 1;
        width: var(--button-size);
        height: var(--button-size);
        position: relative;
        display: flex;
        place-items: center;
        place-content: center;
        align-self: center;
        color: $grey2;
        transition: background-color $transition, color $transition, opacity $transition, transform 0.3s $ease-out-back;
        &.previous {
          justify-self: flex-start;
          left: $gutter2-;
          svg {
            margin-left: -2px;
          }
        }
        &.next {
          justify-self: flex-end;
          left: $gutter2;
          svg {
            margin-left: 2px;
          }
        }
        svg {
          width: calc(var(--button-size) / 2.5);
          height: calc(var(--button-size) / 2.5);
        }
        @include hover {
          transform: scale(1.1);
          background-color: $blue3;
          color: white;
          opacity: 1 !important;
          border-color: transparent;
        }
      }

      .card:not(.featured) {
        > span {
          color: $grey5;
        }
      }

      .card {
        text-align: center;

        &.featured {
          grid-column: 2;
          position: relative;
          grid-row: 1 / span 2;
          max-width: 500px;
          width: clamp(350px, 30vw, 500px);
          height: fit-content;
          z-index: 1;
          align-self: end;

          img {
            transition: opacity $transition;
          }
          @include aspect-ratio('216/300');
          &::after {
            @include loader();
            top: calc(50% - 10px);
            left: calc(50% - 10px);
          }
          + .card.featured {
            opacity: 0;
          }
          .pdf-link {
            height: 100%;
            svg {
              opacity: 0;
            }
          }
          span {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: $spacer0;
            position: absolute;
            left: 0;
            top: 100%;
            transition: color $transition, opacity $transition;
          }
          a {
            display: flex;
            @supports not (aspect-ratio: 1/1) {
              position: absolute;
              place-items: center;
              place-content: center;
            }
          }
          @include hover {
            span {
              color: $grey;
            }
          }
        }
        .pdf-link {
          box-shadow: 0px 0px 20px 3px rgba(black, 0.1);
          &.active {
            border-color: $red;
          }
        }
      }

      @include break-in($break-max + ($base-gap * 6) + $button-size) {
        button.navigation.previous {
          left: 0;
        }
      }
      @include break-in($break-max + ($base-gap * 4)) {
        .container {
          width: calc(100% + #{$gutter5});
          left: $gutter3;
          padding-left: $gutter;
        }
      }
    }
    @include break-in('small-medium') {
      .bounds {
        display: grid;
        grid-gap: $gutter2 $gutter;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        // align-items: baseline;
        @include break-in(medium) {
          grid-template-columns: repeat(4, 1fr);
        }
        @include break-in(small) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        // height: fit-content;
        &.featured {
          display: none;
        }
        span {
          margin-top: $gap0;
          font-weight: bold;
          color: $blue;
          font-size: font-size(14);
          text-align: center;
        }
      }
      button.navigation {
        display: none;
      }
    }
  }
}

/// @see http://www.rightonblackburns.loc/services/value-added-services
main ~ document-viewer {
  padding-top: $spacer;
  padding-bottom: $spacer2;
}
