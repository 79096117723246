document-viewer {
  position: relative;
}
document-viewer .text {
  margin-bottom: var(--spacer0);
}
document-viewer[data-layout=list] tr td {
  padding: 0 !important;
}
document-viewer[data-layout=list] .pdf-link {
  padding: calc(var(--gap) * 0.5) var(--gap);
  display: flex;
  text-decoration: none;
  align-items: center;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
document-viewer[data-layout=list] .pdf-link img {
  border: 1px solid #dcdcdc;
  margin-right: var(--gap);
}
document-viewer[data-layout=list] .pdf-link span {
  margin-right: var(--gap);
}
document-viewer[data-layout=list] .pdf-link svg {
  margin-left: auto;
  --fill: #DF3035;
  width: 20px;
  height: 20px;
}
document-viewer .card .pdf-link {
  position: relative;
  padding: calc(var(--gap) * 0.5);
  text-decoration: none;
  align-items: center;
  border: 1px solid #dcdcdc;
  transition: border-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: white;
  display: flex;
  justify-content: center;
}
document-viewer .card .pdf-link img {
  width: 100%;
  height: auto;
}
document-viewer .card .pdf-link svg {
  --fill: #DF3035;
  --icon-size: clamp(20px, 100%, 50px);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  position: absolute;
  top: calc(50% - var(--icon-size) / 2);
  left: calc(50% - var(--icon-size) / 2);
  width: var(--icon-size);
  height: var(--icon-size);
  opacity: 0;
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  document-viewer .card .pdf-link svg {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
@media (hover: hover) {
  document-viewer .card .pdf-link:hover {
    border-color: #DF3035;
    transform: scale(1.05);
  }
  document-viewer .card .pdf-link:hover svg {
    transform: scale(1.2);
    opacity: 1 !important;
  }
}
document-viewer[data-layout=grid] .container {
  display: grid;
  grid-gap: var(--gutter2) var(--gutter1);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
@media (max-width: 1120px) {
  document-viewer[data-layout=grid] .container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 480px) {
  document-viewer[data-layout=grid] .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
document-viewer[data-layout=grid] .card {
  display: flex;
  flex-direction: column;
}
document-viewer[data-layout=grid] .card span {
  margin-top: calc(var(--gap) * 0.5);
  font-weight: bold;
  color: #131f6b;
  font-size: 0.875em;
}
document-viewer[data-layout=scroller] {
  position: relative;
}
@media (min-width: 981px) {
  document-viewer[data-layout=scroller] {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr minmax(var(--featured-card-width), auto);
    grid-template-rows: repeat(2, auto);
    grid-gap: 0 var(--gutter3);
    justify-items: flex-end;
    --card-width: 220px;
    --featured-card-width: 350px;
  }
  document-viewer[data-layout=scroller].loading .featured.card::after {
    opacity: 1;
    animation-play-state: running;
  }
  document-viewer[data-layout=scroller].loading .featured.card img,
  document-viewer[data-layout=scroller].loading .featured.card span {
    opacity: 0;
  }
  document-viewer[data-layout=scroller].loading .container,
  document-viewer[data-layout=scroller].loading .featured.card {
    pointer-events: none;
  }
  document-viewer[data-layout=scroller] .introduction.text {
    grid-column: 1;
    grid-row: 1;
    height: fit-content;
    max-width: 900px;
    justify-self: flex-start;
    margin-bottom: 0;
  }
  document-viewer[data-layout=scroller] .container {
    display: flex;
    position: relative;
    height: fit-content;
    width: calc(100% + var(--computed-gutter) + var(--gutter3));
    left: var(--gutter3);
    box-sizing: border-box;
    grid-column: 1;
    grid-row: 2;
    overflow: hidden;
    padding-left: calc(var(--computed-gutter));
    padding-right: var(--gutter3);
    padding-top: var(--spacer1);
    padding-bottom: var(--spacer1);
    scroll-behavior: smooth;
  }
  document-viewer[data-layout=scroller] .container .bounds {
    display: grid;
    grid-gap: var(--gutter2);
  }
  document-viewer[data-layout=scroller] .container .bounds .card {
    width: var(--card-width);
    grid-row: 1;
    scroll-snap-align: start;
  }
  document-viewer[data-layout=scroller] .container .bounds .card .pdf-link {
    height: 100%;
  }
  document-viewer[data-layout=scroller] .container .bounds .card .pdf-link svg {
    display: none;
  }
  document-viewer[data-layout=scroller] .container .bounds .card span {
    position: relative;
    bottom: -1.5rem;
  }
  :root:not([data-browser-feature*=touch]) document-viewer[data-layout=scroller] .container ~ button.navigation {
    opacity: 0;
  }
}
@media (min-width: 981px) and (hover: hover) {
  :root:not([data-browser-feature*=touch]) document-viewer[data-layout=scroller] .container:hover ~ button.navigation {
    opacity: 1;
  }
}
@media (min-width: 981px) {
  :root[data-browser-feature*=touch] document-viewer[data-layout=scroller] .container {
    overflow-x: auto;
  }
  document-viewer[data-layout=scroller].scrolled-right button.next, document-viewer[data-layout=scroller].scrolled-left button.previous {
    pointer-events: none;
    opacity: 0 !important;
  }
  document-viewer[data-layout=scroller] button.navigation {
    --button-size: 40px;
    grid-column: 1;
    grid-row: 2;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    z-index: 1;
    width: var(--button-size);
    height: var(--button-size);
    position: relative;
    display: flex;
    place-items: center;
    place-content: center;
    align-self: center;
    color: #6A6A6A;
    transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  document-viewer[data-layout=scroller] button.navigation.previous {
    justify-self: flex-start;
    left: var(--gutter2-);
  }
  document-viewer[data-layout=scroller] button.navigation.previous svg {
    margin-left: -2px;
  }
  document-viewer[data-layout=scroller] button.navigation.next {
    justify-self: flex-end;
    left: var(--gutter2);
  }
  document-viewer[data-layout=scroller] button.navigation.next svg {
    margin-left: 2px;
  }
  document-viewer[data-layout=scroller] button.navigation svg {
    width: calc(var(--button-size) / 2.5);
    height: calc(var(--button-size) / 2.5);
  }
}
@media (min-width: 981px) and (hover: hover) {
  document-viewer[data-layout=scroller] button.navigation:hover {
    transform: scale(1.1);
    background-color: #3d3f7f;
    color: white;
    opacity: 1 !important;
    border-color: transparent;
  }
}
@media (min-width: 981px) {
  document-viewer[data-layout=scroller] .card:not(.featured) > span {
    color: #B1B1B1;
  }
  document-viewer[data-layout=scroller] .card {
    text-align: center;
  }
  document-viewer[data-layout=scroller] .card.featured {
    grid-column: 2;
    position: relative;
    grid-row: 1/span 2;
    max-width: 500px;
    width: clamp(350px, 30vw, 500px);
    height: fit-content;
    z-index: 1;
    align-self: end;
    --aspect-ratio: 216/300;
    aspect-ratio: var(--aspect-ratio);
  }
  document-viewer[data-layout=scroller] .card.featured img {
    transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  }
  @supports not (aspect-ratio: 216/300) {
    document-viewer[data-layout=scroller] .card.featured {
      aspect-ratio: initial;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
      height: 0;
    }
    document-viewer[data-layout=scroller] .card.featured > * {
      position: absolute;
    }
  }
  document-viewer[data-layout=scroller] .card.featured::after {
    --size: 12px;
    content: "";
    position: absolute;
    top: calc(50% - var(--size) / 2);
    left: calc(50% - var(--size) / 2);
    opacity: 0;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 2px solid var(--loader-colour, #969695);
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation-name: loader-rotate;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    animation-play-state: paused;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
  @keyframes loader-rotate {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  document-viewer[data-layout=scroller] .card.featured + .card.featured {
    opacity: 0;
  }
  document-viewer[data-layout=scroller] .card.featured .pdf-link {
    height: 100%;
  }
  document-viewer[data-layout=scroller] .card.featured .pdf-link svg {
    opacity: 0;
  }
  document-viewer[data-layout=scroller] .card.featured span {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: var(--spacer0);
    position: absolute;
    left: 0;
    top: 100%;
    transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  }
  document-viewer[data-layout=scroller] .card.featured a {
    display: flex;
  }
  @supports not (aspect-ratio: 1/1) {
    document-viewer[data-layout=scroller] .card.featured a {
      position: absolute;
      place-items: center;
      place-content: center;
    }
  }
}
@media (min-width: 981px) and (hover: hover) {
  document-viewer[data-layout=scroller] .card.featured:hover span {
    color: #969695;
  }
}
@media (min-width: 981px) {
  document-viewer[data-layout=scroller] .card .pdf-link {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  }
  document-viewer[data-layout=scroller] .card .pdf-link.active {
    border-color: #DF3035;
  }
}
@media (min-width: 981px) and (max-width: 1816px) {
  document-viewer[data-layout=scroller] button.navigation.previous {
    left: 0;
  }
}
@media (min-width: 981px) and (max-width: 1744px) {
  document-viewer[data-layout=scroller] .container {
    width: calc(100% + var(--gutter5));
    left: var(--gutter3);
    padding-left: var(--gutter1);
  }
}
@media (max-width: 980px) {
  document-viewer[data-layout=scroller] .bounds {
    display: grid;
    grid-gap: var(--gutter2) var(--gutter1);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
@media (max-width: 980px) and (max-width: 1120px) {
  document-viewer[data-layout=scroller] .bounds {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 980px) and (max-width: 480px) {
  document-viewer[data-layout=scroller] .bounds {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 980px) {
  document-viewer[data-layout=scroller] .card {
    display: flex;
    flex-direction: column;
  }
  document-viewer[data-layout=scroller] .card.featured {
    display: none;
  }
  document-viewer[data-layout=scroller] .card span {
    margin-top: calc(var(--gap) * 0.5);
    font-weight: bold;
    color: #131f6b;
    font-size: 0.875em;
    text-align: center;
  }
  document-viewer[data-layout=scroller] button.navigation {
    display: none;
  }
}

main ~ document-viewer {
  padding-top: var(--spacer1);
  padding-bottom: var(--spacer2);
}